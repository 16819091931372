import {
  get,
  i18nFormat,
  UnpaidInquiryOrderStatusList,
} from '@unionfab/ufc-shop-commons';
import { getUfcShopComposedApi } from '@unionfab/ufc-shop-commons';
import { Alert, Button, Checkbox, Collapse, Input, message, Modal } from 'antd';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '@/stores';

import { ShopNewQuoteSteps } from '../../../components/info/ShopNewQuoteSteps';
import styles from './index.module.less';
import { ShopQuoteBillingInfo } from './ShopQuoteBillingInfo';
import { ShopQuoteCheckoutSummary } from './ShopQuoteCheckoutSummary';
// import { ShopQuoteCompletePersonInfoForm } from './ShopQuoteCompletePersonInfoForm';
import { ShopQuoteShippingAddressMgt } from './ShopQuoteShippingAddressMgt';
import { ShopQuoteShippingMethodMgt } from './ShopQuoteShippingMethodMgt';

export interface ShopQuoteCheckoutPageProps {
  className?: string;
  style?: Record<string, string | number>;
}

export const ShopQuoteCheckoutPage = ({
  className,
  style,
}: ShopQuoteCheckoutPageProps) => {
  const navigate = useNavigate();
  const orderCode = useParams().orderCode;
  const [modalVisible, toggleVisible] = useState<boolean>(false);
  const [confirmLoading, toggleConfirmLoading] = useState<boolean>(false);

  const store = getUseShopQuoteStoreByCode(orderCode);

  const {
    order,
    allowEdit,
    shopOrderPrice,
    orderQuotation,
    isBillingInfoRequired,
  } = store(s => ({
    order: s.shopOrder,
    allowEdit: s.allowEdit,
    shopOrderPrice: s.shopOrderPrice,
    orderQuotation: s.shopOrderQuotation,
    isBillingInfoRequired: s.isBillingInfoRequired,
  }));

  const orderStatus = get(order, o => o.status);
  const showAlert =
    !!shopOrderPrice &&
    shopOrderPrice.status !== 'OK' &&
    (orderStatus == 'WAIT_SUBMIT' || orderStatus == 'WAIT_CUSTOM_CONFIRM');

  useEffect(() => {
    if (order && !UnpaidInquiryOrderStatusList.includes(orderStatus)) {
      navigate(-1);
    }
  }, [order]);

  useEffect(() => {
    if (
      !get(orderQuotation, o => o.customerCheckedAt) &&
      orderStatus == 'WAIT_CUSTOM_CONFIRM'
    ) {
      toggleVisible(true);
    }
  }, [order, orderQuotation]);

  const confirmQuotationModal = useMemo(() => {
    if (
      !get(orderQuotation, o => o.customerCheckedAt) &&
      orderStatus == 'WAIT_CUSTOM_CONFIRM'
    ) {
      const onConfirm = async () => {
        try {
          toggleConfirmLoading(true);

          const resp =
            await getUfcShopComposedApi().shopOrderUpdateApi.confirmShopOrderQuotation(
              order.code,
              orderQuotation.revision,
            );

          !resp && message.error(i18nFormat('Failed to confirm quotation'));
        } catch (e) {
          console.log('>>>ShopQuoteCheckoutPage>>>onConfirm', e);
          message.error(i18nFormat('Failed to confirm quotation'));
        } finally {
          toggleVisible(false);
          toggleConfirmLoading(false);
        }
      };

      const onOkButton = (
        <Button
          key="confirm"
          type="primary"
          onClick={onConfirm}
          loading={confirmLoading}
        >
          {i18nFormat('Confirm')}
        </Button>
      );

      return (
        <Modal
          closable={false}
          open={modalVisible}
          footer={[onOkButton]}
          title={i18nFormat('Notice')}
        >
          <span style={{ fontWeight: 'bold' }}>
            {i18nFormat(
              'The total price changed, please check the updated price',
            )}
          </span>
        </Modal>
      );
    }

    return <></>;
  }, [modalVisible, confirmLoading, order, orderQuotation]);

  const renderShopOrderRemark = () => {
    const onUpdaetRemark = (r: string) => {
      ShopOrderQuoteStoreModifiers.updateOrder({
        orderCode,
        autoSave: false,
        onChange: o => {
          o.requirements.customerSelections = {
            ...get(o, o => o.requirements.customerSelections),
            remark: {
              ...get(o, o => o.requirements.customerSelections.remark),
              remark: r,
            },
          };
        },
      });
    };

    return (
      <Input.TextArea
        disabled={!allowEdit}
        onChange={({ target: { value } }) => onUpdaetRemark(value)}
        placeholder={i18nFormat('Please note your special requirements.')}
        onBlur={() => ShopOrderQuoteStoreModifiers.saveOrderToServer(orderCode)}
        value={get(order, o => o.requirements.customerSelections.remark.remark)}
      />
    );
  };

  return (
    <div
      id="ShopQuoteCheckoutPage"
      className={cn(className, styles.container)}
      style={{ ...style, paddingTop: showAlert ? '50px' : 0 }}
    >
      {showAlert && (
        <Alert
          closable
          className={styles.alert}
          type="warning"
          message={i18nFormat(
            `This order's price requires a manual quote. You must click "Request a Manual Quote" to submit your order, after which our engineers can begin the quoting process.`,
          )}
        />
      )}

      <div className={styles.content}>
        <div className={styles.top}>
          <ShopNewQuoteSteps currentIndex={2} orderCode={orderCode} />
        </div>
        <div className={styles.body}>
          <div className={styles.left}>
            <div className={styles.leftItem}>
              <h3>1.&nbsp;{i18nFormat('Shipping Address')}</h3>
              <ShopQuoteShippingAddressMgt orderCode={orderCode} />
            </div>

            <Collapse
              bordered={false}
              expandIconPosition="end"
              defaultActiveKey={['billing_info', 'ship_method', 'order_remark']}
              className={styles.collapse}
            >
              <Collapse.Panel
                key="billing_info"
                header={
                  <span onClick={e => e.stopPropagation()}>
                    2.&nbsp;
                    <Checkbox
                      style={{
                        fontSize: '1.125rem',
                        fontFamily: 'PingFangSC-Semibold',
                      }}
                      checked={isBillingInfoRequired}
                      onChange={({ target: { checked } }) => {
                        store.setState({ isBillingInfoRequired: checked });
                      }}
                    >
                      {i18nFormat('Business Customer')}
                    </Checkbox>
                  </span>
                }
              >
                <ShopQuoteBillingInfo orderCode={orderCode} />
              </Collapse.Panel>
              <Collapse.Panel key="ship_method" header="3. Shipping Method">
                <ShopQuoteShippingMethodMgt orderCode={orderCode} />
              </Collapse.Panel>
              <Collapse.Panel
                key="order_remark"
                header={`4. ${i18nFormat('Order Remark')} `}
              >
                {renderShopOrderRemark()}
              </Collapse.Panel>
            </Collapse>
          </div>
          <ShopQuoteCheckoutSummary
            className={styles.right}
            orderCode={orderCode}
          />
        </div>
      </div>
      {/* 注释报价待确认提示 */}
      {/* {confirmQuotationModal} */}
    </div>
  );
};

ShopQuoteCheckoutPage.displayName = 'ShopQuoteCheckoutPage';
