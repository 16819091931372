import {
  get,
  i18nFormat,
  OverseaOrderStatus,
} from '@unionfab/ufc-shop-commons';
import { Pagination, Spin, Typography } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import {
  loadOrderCountsInQuoteListStore,
  loadOrdersInQuoteListStore,
  setQueryParamsInQuoteListStore,
  useQuoteListStore,
} from '@/stores';

import {
  CreateTimePicker,
  SelectInput,
} from '../../../../commons/components/Element/DatePicker';
import { OrdersTable } from '../../components/OrdersTable';
import { StatusTabs } from '../../components/OrderStatusTab';
import styles from './index.module.less';

export interface QuoteHistoryProps {
  className?: string;
  style?: Record<string, string | number>;
}

export const QuoteHistory = ({ className, style }: QuoteHistoryProps) => {
  const { orderList, orderCounts, orderPagination, isLoading, queryParams } =
    useQuoteListStore();

  const { sortBy, startDateTime, endDateTime, orderStatus } = queryParams;

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    loadOrdersInQuoteListStore();
    loadOrderCountsInQuoteListStore();
  };

  const setSearchText = (searchText: string) => {
    if (isNil(searchText) || searchText == '') {
      setQueryParamsInQuoteListStore({ ...queryParams, searchText: null });
    } else {
      useQuoteListStore.setState({
        queryParams: { ...queryParams, searchText },
      });
    }
  };

  const OrderPaginationEle = useMemo(() => {
    const totalElements = get(orderPagination, o => o.totalElements, 0);

    if (typeof totalElements == 'number' && totalElements > 0) {
      return (
        <Pagination
          size="small"
          defaultPageSize={3}
          total={totalElements}
          showSizeChanger
          current={get(queryParams, q => q.pageNum, 1)}
          className={styles.pagination}
          pageSizeOptions={[3, 10, 15, 20]}
          onChange={(pageNum, pageSize) => {
            setQueryParamsInQuoteListStore({
              ...queryParams,
              pageNum,
              pageSize,
            });
          }}
        />
      );
    }
  }, [orderPagination]);

  return (
    <div className={cn(className, styles.container)} style={style}>
      <div className={styles.header}>
        <Typography.Title level={3}>{i18nFormat('Quotes')}</Typography.Title>
        <div className={styles.selectorLine}>
          <CreateTimePicker
            sort={sortBy}
            date={
              startDateTime && endDateTime
                ? [dayjs(startDateTime), dayjs(endDateTime)]
                : null
            }
            setSortType={sortBy =>
              setQueryParamsInQuoteListStore({ ...queryParams, sortBy })
            }
            setDate={([start, end]) => {
              const endDateTime = end ? end.endOf('D').format() : null;
              const startDateTime = start ? start.startOf('D').format() : null;

              setQueryParamsInQuoteListStore({
                ...queryParams,
                startDateTime,
                endDateTime,
              });
            }}
          />
          <SelectInput
            onSearch={onRefresh}
            setSearchText={setSearchText}
            searchText={queryParams.searchText || ''}
          />
        </div>
      </div>

      <div className={styles.content}>
        <StatusTabs
          statusList={['WAIT_PAYMENT', 'WAIT_SUBMIT', 'REVIEWING']}
          statusStats={orderCounts}
          activeKey={orderStatus}
          onTabsChange={(orderStatus: string) =>
            setQueryParamsInQuoteListStore({
              ...queryParams,
              pageNum: 1,
              orderStatus: orderStatus as OverseaOrderStatus,
            })
          }
        />

        <div className={cn(isLoading && styles.blur)}>
          <OrdersTable
            isQuoteTable={true}
            orders={orderList || []}
            className={styles.ordersTable}
            loadOrders={onRefresh}
          />
          {OrderPaginationEle}
          <Spin className={styles.spin} spinning={isLoading} />
        </div>
      </div>
    </div>
  );
};

QuoteHistory.displayName = 'QuoteHistory';
