import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  i18nFormat,
  UfcShopTokenUtils,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Spin } from 'antd';
import cn from 'classnames';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import useCNCOptions from '../../../../../../ufc-shop-commons/src/stores/quote/shop-order-quote/useCNCOptions';
import {
  loadOrderInShopEditorStore,
  ShopOrderQuoteStoreModifiers,
  useGlobalStore,
  useShopQuoteUiStore,
} from '../../../../stores';
import { ShopNewQuoteSteps } from '../../components/info/ShopNewQuoteSteps';
import { UnpaidShopOrderCard } from '../../components/info/UnpaidShopOrderCard';
import { TechCategorySelector } from '../../components/select/TechCategorySelector';
import { QuoteFilesUploader } from '../../components/uploader';
import styles from './index.module.less';

export interface NewShopQuoteProps {
  className?: string;
  style?: Record<string, string | number>;
}

export const NewShopQuote = ({ className, style }: NewShopQuoteProps) => {
  const [spinning, toggleSpinning] = useState<boolean>(false);
  const { isDemoUser, hasAuthenticated } = useGlobalStore(g => ({
    isDemoUser: g.isDemoUser,
    hasAuthenticated: g.hasAuthenticated,
  }));
  /** 获取三笔最近更新的未支付订单 */
  const { data: unpaidOrders, isLoading } = useQuery(
    ['orders'],
    async () => {
      const resp =
        await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrders({
          pageNum: 0,
          pageSize: 3,
          sortBy: 'desc(updateTime)',
          tenantId: getTenantIdFromGConfig(),
          status: ['WAIT_SUBMIT', 'WAIT_REVIEW', 'WAIT_CUSTOM_CONFIRM'],
        });

      return resp.data;
    },
    { enabled: !isDemoUser && hasAuthenticated },
  );

  const navigate = useNavigate();
  const curTechCategory = useShopQuoteUiStore(s => s.curTechCategory);

  const { setOpts: setCNCOpts, options: cncOpts } = useCNCOptions();

  return (
    <div
      style={style}
      id="NewShopQuote"
      className={cn(className, styles.container)}
    >
      <Spin spinning={spinning}>
        <div
          className={cn(
            styles.content,
            (isDemoUser || !hasAuthenticated) && styles.unAuthContent,
          )}
        >
          {/* <ShopNewQuoteSteps currentIndex={1} className={styles.quoteSteps} /> */}
          <TechCategorySelector
            tech={curTechCategory}
            className={styles.tecSelector}
            onChange={(_, t) => {
              useShopQuoteUiStore.setState({ curTechCategory: t });
            }}
          />

          <div className={styles.filesUploaderWrapper}>
            <div className={styles.filesUploaderTitle}>
              <h3 className={styles.subTitle}>
                {i18nFormat('Upload design and configure parts to get a quote')}
              </h3>
              <Link target="_blank" to={`/pdfviewer?doc=design_spec`}>
                {i18nFormat('Design specification')} &gt;
              </Link>
            </div>
            <QuoteFilesUploader
              uploaderId={'quote-editor-d3model-uploader'}
              onBatchUploadSuccess={async (files: S.D3ModelFile[]) => {
                let storedCNCOpts = cncOpts;
                if (
                  curTechCategory.type === 'CNC' &&
                  Object.keys(cncOpts).length === 0
                ) {
                  const res: any =
                    await getUfcShopComposedApi().shopOrderQueryApi.getCNCOptions();
                  setCNCOpts(res.cnc);
                  storedCNCOpts = res.cnc;
                }

                /** 映射一下后端的值 */
                const techMap = new Map([
                  ['3DPRINT', '3D_PRINTING'],
                  ['CNC', 'CNC'],
                ]);
                try {
                  // 创建新的订单
                  const newOrderCode =
                    await S.getUfcShopComposedApi().shopOrderUpdateApi.createShopOrder(
                      {
                        requirements: { quotation: { currency: 'USD' } },
                        technology: techMap.get(curTechCategory.type) as
                          | '3D_PRINTING'
                          | 'CNC',
                      },
                    );

                  toggleSpinning(true);

                  await loadOrderInShopEditorStore(newOrderCode);

                  // 将文件添加到 Store 内
                  await ShopOrderQuoteStoreModifiers.addOrUpdateOrderItemByFile(
                    newOrderCode,
                    files,
                    // 以回调的形式进行页面跳转防止在订单提交前页面已经跳转导致的订单更新失败
                    () => {
                      toggleSpinning(false);

                      if (hasAuthenticated && !isDemoUser) {
                        navigate(`/new_shop_quote/${newOrderCode}`);
                      } else {
                        navigate(`/anonymous_new_shop_quote/${newOrderCode}`);
                      }
                    },
                    storedCNCOpts,
                  );
                } catch (e) {
                  toggleSpinning(false);

                  console.log('>>>NewShopQuote>>>onBatchUploadSuccess', e);
                }
              }}
            />
          </div>
        </div>

        {!isDemoUser && hasAuthenticated && (
          <div className={styles.recentOrdersWrapper}>
            <h3 className={styles.subTitle}>
              {i18nFormat('Pick Up Where You Left Off')}
            </h3>

            <Spin spinning={isLoading}>
              <div className={styles.orders}>
                {(unpaidOrders || []).map(o => (
                  <UnpaidShopOrderCard order={o} key={o.id} />
                ))}
              </div>
            </Spin>
          </div>
        )}
      </Spin>
    </div>
  );
};

NewShopQuote.displayName = 'NewShopQuote';

export default NewShopQuote;
