import { getUfcShopComposedApi, i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, Checkbox, message, Modal, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import cn from 'classnames';
import { compact, isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getUseShopQuoteStoreByCode,
  loadOrderInShopEditorStore,
  ShopOrderQuoteStoreModifiers,
  useGlobalStore,
} from '@/stores';

import { useAppNavigate } from '../../../../../shared';
import styles from './index.module.less';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export interface ShopQuoteCheckoutContinueToPayBtnProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
}

export const ShopQuoteCheckoutContinueToPayBtn = ({
  className,
  style,

  orderCode,
}: ShopQuoteCheckoutContinueToPayBtnProps) => {
  const navigate = useNavigate();
  const [loading, toggleLoading] = useState<boolean>(false);
  const [keepCalculatingCount, setKeepCalculatingCount] = useState(0);
  const [isKeepCalculatingModalOpen, setIsKeepCalculatingModalOpen] =
    useState(false);
  const [priceCalculatingFailedModalOpen, setPriceCalculatingFailedModalOpen] =
    useState(false);

  const initializeRef = useRef(false);

  const nav = useAppNavigate();

  const { isDemoUser, hasAuthenticated } = useGlobalStore(g => ({
    isDemoUser: g.isDemoUser,
    hasAuthenticated: g.hasAuthenticated,
  }));

  const useShopQuoteStore = getUseShopQuoteStoreByCode(orderCode);

  const {
    isLoading,
    shopOrder,
    allowEdit,
    shopOrderPrice,
    isAgreeTermOfService,
    shopOrderQuotation,
  } = useShopQuoteStore(s => ({
    isLoading: s.isLoading,
    allowEdit: s.allowEdit,
    shopOrder: s.shopOrder,
    shopOrderPrice: s.shopOrderPrice,
    isAgreeTermOfService: s.isAgreeTermOfService,
    shopOrderQuotation: s.shopOrderQuotation,
  }));

  const isRfq = shopOrderPrice && shopOrderPrice.status !== 'OK';

  const onSubmit = async () => {
    if (!useShopQuoteStore.getState().isAgreeTermOfService) {
      return Modal.info({
        width: 500,
        maskClosable: true,
        bodyStyle: { padding: '20px 24px' },
        title: i18nFormat('Please review and agree to the terms of service.'),
        okText: i18nFormat(
          `${isRfq ? 'Request a Manual Quote' : 'Continue to Pay'}`,
        ),
        onOk: () => {
          if (useShopQuoteStore.getState().isAgreeTermOfService) {
            onSubmit();
          }
        },
        content: (
          <Checkbox
            defaultChecked={isAgreeTermOfService}
            className={styles.agreeTermOfService}
            onChange={e =>
              useShopQuoteStore.setState({
                isAgreeTermOfService: e.target.checked,
              })
            }
          >
            {i18nFormat("I agree to Unionfab's")}&nbsp;
            <a onClick={() => nav.navigateToPdfPageNewTab('csa')}>
              {i18nFormat('Customer Service Agreement')}
            </a>
          </Checkbox>
        ),
      });
    }

    /** 跳转支付钱判断是否为匿名用户或未登录用户 */
    if (!hasAuthenticated || (hasAuthenticated && isDemoUser)) {
      message.warning(i18nFormat('Please log in before paying for the order.'));
      return;
    }

    try {
      toggleLoading(true);

      const hasAddress = S.get(shopOrder, s => s.delivery.items, []).length > 0;

      if (!hasAddress) {
        return message.warning(i18nFormat('Please select a shipping address'));
      }

      const expressId = S.get(
        shopOrder,
        s => s.requirements.customerSelections.delivery.expressId,
      );

      if (!expressId) {
        return message.warning(i18nFormat('Please select a shipping method'));
      }

      /** 提交之前先保存一下订单方防止订单备注未更新 */
      await ShopOrderQuoteStoreModifiers.saveOrderToServer(orderCode);

      let resp = true;

      const billingInfo = useShopQuoteStore.getState().billingInfo;

      /** 判断是否需要更新账单数据 */
      if (S.isValid(billingInfo)) {
        const isRequired = useShopQuoteStore.getState().isBillingInfoRequired;

        /** 表单展开则为必填 */
        if (typeof isRequired == 'boolean' && isRequired) {
          if (compact(Object.values(billingInfo)).length !== 6) {
            useShopQuoteStore.setState({ isBillingInfoValid: false });

            return message.error(i18nFormat('Incomplete form.'));
          }
        }

        const remoteInfo =
          await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderBillingInfo(
            orderCode,
            false,
          );

        if (!isEqual(billingInfo, remoteInfo)) {
          resp =
            await getUfcShopComposedApi().shopOrderUpdateApi.updateShopOrderBillingInfo(
              orderCode,
              billingInfo,
            );
        }
      }

      const nowShopOrderPrice = useShopQuoteStore.getState().shopOrderPrice;
      const nowShopOrderQuotation =
        useShopQuoteStore.getState().shopOrderQuotation;
      const nowIsRfq =
        (nowShopOrderPrice && nowShopOrderPrice.status !== 'OK') ||
        nowShopOrderQuotation.status === 'COMPUTING' ||
        isLoading;

      if (
        shopOrder.status == 'WAIT_SUBMIT' ||
        (shopOrder.status == 'WAIT_CUSTOM_CONFIRM' && nowIsRfq)
      ) {
        // 判断当前订单状态，如果为 WAIT_SUBMIT 则切换到 WAIT_REVIEW
        resp =
          await getUfcShopComposedApi().shopOrderUpdateApi.submitShopOrderByCode(
            orderCode,
          );

        if (!resp) {
          toggleLoading(false);
          return message.error(i18nFormat('Order submission failed'));
        }
      }

      if (resp && !nowIsRfq) {
        // 从服务端抓取数据
        const newOrder =
          await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderByCode(
            orderCode,
          );
        // 判断当前是否需要自动确认
        if (newOrder.status === 'WAIT_REVIEW') {
          resp =
            await getUfcShopComposedApi().shopOrderUpdateApi.confirmShopOrderByCode(
              orderCode,
            );
        }

        /** 更新订单信息 */
        await loadOrderInShopEditorStore(orderCode);

        await S.sleep(500);

        if (resp) {
          toggleLoading(false);
          // 跳转支付页面
          navigate(`/new_shop_quote/${orderCode}/payment`);
        }
      }

      if (resp && nowIsRfq) {
        toggleLoading(false);
        navigate(`/new_shop_quote/${orderCode}/checkout-success`);
      }
    } catch (e) {
      console.log('>>>ShopQuoteCheckoutContinueToPayBtn>>>submit error', e);
    } finally {
      toggleLoading(false);
    }
  };

  /** 渲染还在等待人工报价的 */
  const rfqDetailReason = useMemo(() => {
    let reasonStr = '';

    const [reason] = S.get(shopOrderQuotation, q => q.production.reason, []);

    if (isRfq) {
      reasonStr = S.getShopOrderQuotationRfqDetailReason(null);
    }

    if (reason && reason.type) {
      reasonStr = S.getShopOrderQuotationRfqDetailReason(reason.type);
    }

    if (S.get(shopOrderQuotation, q => q.production.status) == 'COMPUTING') {
      reasonStr = S.getShopOrderQuotationRfqDetailReason('computing');
    }

    if (reasonStr == '') return <></>;

    return <div className={styles.rfqDesc}>{i18nFormat(reasonStr)}</div>;
  }, [isRfq, shopOrderQuotation]);

  /** 订单提交后且为 RFQ 的订单等待人工报价不允许提交 */
  const disabled = !allowEdit && isRfq && shopOrder.status == 'WAIT_REVIEW';

  const checkBtnTxt = useMemo(() => {
    if (!allowEdit && isRfq && shopOrder && shopOrder.status == 'WAIT_REVIEW') {
      return 'Wait for Checkout';
    }
    if (
      isLoading ||
      (S.ShopOrderAccessors.isReadyToCalcPrice(shopOrder)
        ? !shopOrderPrice
        : false) ||
      S.get(shopOrderQuotation, s => s.status === 'COMPUTING') ||
      S.get(shopOrderPrice, s => s.status) === 'COMPUTING'
    ) {
      return 'Request a Manual Quote';
    }

    return isRfq ? 'Request a Manual Quote' : 'Continue to Pay';
  }, [
    isRfq,
    shopOrder,
    allowEdit,
    isLoading,
    shopOrderPrice,
    shopOrderQuotation,
  ]);

  const submitButton = useMemo(() => {
    const btnProps: ButtonProps = {
      loading,
      disabled,
      block: true,
      size: 'large',
      type: 'primary',
      onClick: onSubmit,
      children: i18nFormat(checkBtnTxt),
      className: cn(
        isRfq && 'animate__animated animate__flash animate__repeat-2',
      ),
    };

    if (!S.isValidArray(shopOrder.items || [])) {
      return (
        <Tooltip title={i18nFormat('Please upload the model file first')}>
          <Button {...btnProps} disabled={true} />
        </Tooltip>
      );
    }

    return (
      <Button {...btnProps} disabled={loading}>
        {i18nFormat(checkBtnTxt)}
      </Button>
    );
  }, [disabled, shopOrder, loading, checkBtnTxt, isAgreeTermOfService]);

  const isSpining = useMemo(() => {
    return (
      (S.ShopOrderAccessors.isReadyToCalcPrice(shopOrder)
        ? !shopOrderPrice
        : false) || shopOrderQuotation.status === 'COMPUTING'
    );
  }, [
    shopOrder,
    shopOrderPrice,
    S.ShopOrderAccessors.isReadyToCalcPrice(shopOrder),
  ]);

  /** 处理未计算出价格弹框 */
  const handleKeepCalculatingModal = () => {
    setTimeout(() => {
      const currentIsSpining =
        (useShopQuoteStore.getState().shopOrderPrice &&
          useShopQuoteStore.getState().shopOrderPrice.status === 'COMPUTING') ||
        useShopQuoteStore.getState().shopOrderQuotation.status === 'COMPUTING';
      if (currentIsSpining) {
        if (keepCalculatingCount == 2) {
          setPriceCalculatingFailedModalOpen(true);
        } else {
          setIsKeepCalculatingModalOpen(true);
          setKeepCalculatingCount(keepCalculatingCount + 1);
        }
      } else {
        setIsKeepCalculatingModalOpen(false);
        setPriceCalculatingFailedModalOpen(false);
      }
    }, 30000);
  };

  const handleCancelKeepCalculatingModal = () => {
    setIsKeepCalculatingModalOpen(false);
    handleKeepCalculatingModal();
  };

  // 计算出来价格后，关闭弹窗
  useEffect(() => {
    if (!isSpining) {
      setKeepCalculatingCount(0);
      setIsKeepCalculatingModalOpen(false);
      setPriceCalculatingFailedModalOpen(false);
    }
  }, [isSpining]);

  useEffect(() => {
    if (initializeRef.current) return;
    initializeRef.current = true;
    const orderStatus = S.get(shopOrder, o => o.status);
    // 订单状态为 WAIT_SUBMIT 时，才需要处理计算价格弹窗
    orderStatus === 'WAIT_SUBMIT' && handleKeepCalculatingModal();
  }, []);

  return (
    <>
      <div
        id="ShopQuoteCheckoutContinueToPayBtn"
        className={cn(className, styles.container)}
        style={style}
      >
        {submitButton}
        {rfqDetailReason}
      </div>
      <Modal
        open={isKeepCalculatingModalOpen}
        onCancel={handleCancelKeepCalculatingModal}
        className={styles.calculatingModal}
        footer={[
          <Button key="waiting" onClick={handleCancelKeepCalculatingModal}>
            {i18nFormat('Keep Waiting')}
          </Button>,
          <Button
            key="request"
            type="primary"
            onClick={() => {
              setIsKeepCalculatingModalOpen(false);
              onSubmit();
            }}
          >
            {i18nFormat(
              `${isSpining ? 'Request a Manual Quote' : 'Continue to Pay'}`,
            )}
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
          <ExclamationCircleOutlined
            style={{ fontSize: '24px', color: '#1890ff' }}
          />
          <div>
            <p style={{ margin: 0 }}>
              {i18nFormat(
                'It will take a long time to calculate. Click "Request a Manual Quote". You can receive an email when the quoting process is finished by our engineers.',
              )}
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={priceCalculatingFailedModalOpen}
        onCancel={() => navigate('/orders_history/quotes')}
        className={styles.calculatingModal}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              navigate('/orders_history/quotes');
            }}
          >
            {i18nFormat('Cancel')}
          </Button>,
          <Button
            key="request"
            type="primary"
            onClick={() => {
              setPriceCalculatingFailedModalOpen(false);
              onSubmit();
            }}
          >
            {i18nFormat(
              `${isSpining ? 'Request a Manual Quote' : 'Continue to Pay'}`,
            )}
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
          <ExclamationCircleOutlined
            style={{ fontSize: '24px', color: '#1890ff' }}
          />
          <div>
            <p style={{ margin: 0 }}>
              {i18nFormat(
                'Instant quote failed. Click "Request a Manual Quote". You can receive an email when the quoting process is finished by our engineers.',
              )}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

ShopQuoteCheckoutContinueToPayBtn.displayName =
  'ShopQuoteCheckoutContinueToPayBtn';
