import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import {
  getLocale,
  getQuotationEditRecord,
  getUfcShopComposedApi,
  i18nFormat,
  getShopOrderDetailByCodeForOverseaCustomerWithCoupon,
} from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';
import { useAsyncEffect } from 'ahooks';
import { Button, Spin, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useOrderDetailsStore } from '@/stores';
import { useGlobalStore } from '@/stores';
import { useShopCouponStore } from '@/stores';

import styles from './index.module.less';

const LK_QUOTATION_SCALE = 'LK_QUOTATION_SCALE';

export const OrderInvoice = () => {
  const [scale, setScale] = useState(
    parseFloat(localStorage.getItem(LK_QUOTATION_SCALE)) || 1,
  );

  const { getCoupon, clearExpiredCoupons } = useShopCouponStore(
    state => ({
      getCoupon: state.getCoupon,
      clearExpiredCoupons: state.clearExpiredCoupons,
    }),
  );

  const [couponPrice, setCouponPrice] = useState(0);

  const inquiryOrderCode = useParams().orderCode;
  const isRussiaLocal = getLocale() === 'ru';

  const {
    loadOrder,
    order: storeOrder,
    orderStepTimeMap,
  } = useOrderDetailsStore();
  const { setShowHeader } = useGlobalStore();

  const getInvoiceType = () => {
    return S.getUrlParameterByName('type', window.location.href) || 'quotation';
  };

  const { data: billingInfo } = useQuery(
    ['billing_info', inquiryOrderCode],
    () =>
      getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderBillingInfo(
        inquiryOrderCode,
        false,
      ),
    {
      enabled: getInvoiceType() !== 'quotation',
    },
  );

  console.log('>>>billingInfo>>>', billingInfo);

  /** 是商业发票类型吗 */
  const isCommercialType = getInvoiceType() === 'commercial';

  const getTitle = () => {
    if (getInvoiceType() === 'proforma') {
      return i18nFormat('Proforma Invoice');
    }

    if (getInvoiceType() === 'commercial') {
      return 'Commercial Invoice';
    }

    return 'Quotation';
  };

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [editRecordRes, setEditRecordRes] = React.useState<
    Record<string, string>
  >({});

  const [couponCode, setCouponCode] = useState<string | null>(null);

  useEffect(() => {
    clearExpiredCoupons();
    if (inquiryOrderCode) {
      const storeCoupon = getCoupon(inquiryOrderCode);
      if (storeCoupon) {
        setCouponCode(storeCoupon.code);
      }
    }
  }, [inquiryOrderCode, getCoupon]);

  useAsyncEffect(async () => {
    setShowHeader(false);
    setIsLoading(true);

    if (inquiryOrderCode) {
      try {
        await loadOrder(inquiryOrderCode);

        const { data: records } = await getQuotationEditRecord(
          inquiryOrderCode,
        );

        setEditRecordRes(records);
      } catch (e) {
        console.log('>>>OrderInvoice>>>err', e);
      }
    }

    setIsLoading(false);
  }, [inquiryOrderCode]);

  const inquiryOrder =
    storeOrder?.code === inquiryOrderCode ? storeOrder : undefined;

  // 形式发票才计算折扣
  useAsyncEffect(async () => {
    if (getInvoiceType() === 'proforma' && inquiryOrder && couponCode) {
      try {
        const result =
          await getShopOrderDetailByCodeForOverseaCustomerWithCoupon(
            inquiryOrderCode,
            inquiryOrder.customerShipOption.expressId,
            couponCode,
          );
        if (result[couponCode]) {
          setCouponPrice(
            result[couponCode].price.amount -
              result[couponCode].couponApplied.price.amount,
          );
        }
      } catch (err) {
        console.error('Failed to calculate price with coupon:', err);
      }
    }
  }, [inquiryOrder, couponCode]);

  const getPriceUnit = () =>
    inquiryOrder && inquiryOrder.currency
      ? S.getCurrencySymbolByType(inquiryOrder.currency)
      : 'USD';

  const getDate = () => {
    if (getInvoiceType() === 'proforma') {
      const date = S.get(orderStepTimeMap, m => m.confirmTime.value);

      // 确认报价时间
      return date ? `${dayjs(date).format('MM/DD/YYYY')} EDT` : 'None';
    }

    if (getInvoiceType() === 'commercial') {
      const date = S.get(orderStepTimeMap, m => m.payTime.value);

      // 付款时间
      return date ? `${dayjs(date).format('MM/DD/YYYY')} EDT` : 'None';
    }

    // 创建、提交时间
    return S.get(inquiryOrder, order => order.submitAt)
      ? `${dayjs(inquiryOrder.submitAt).format('MM/DD/YYYY')} EDT`
      : 'None';
  };

  const columns: ColumnsType<S.InquiryOrderItem> = [
    {
      key: 'Preview',
      title: 'Preview',
      render: (_, item: S.InquiryOrderItem) => {
        return (
          <D3ModelAvatar
            key={item.printFiles[0].id}
            size={80}
            model={item.printFiles[0].modelFile}
          />
        );
      },
    },
    {
      key: 'File name',
      title: i18nFormat('File name'),
      render: (_, record: S.InquiryOrderItem) => (
        <div
          style={{
            flexWrap: 'wrap',
            wordWrap: 'break-word',
            maxWidth: 80,
          }}
        >
          {S.get(editRecordRes, r => r[`item_${record.id}_name`]) ||
            S.get(record, r => r.modelFiles[0].name, 'no model name')}
        </div>
      ),
    },
    {
      key: 'Material',
      title: 'Material',
      render: (_, record: S.InquiryOrderItem) =>
        S.get(editRecordRes, r => r[`item_${record.id}_material`]) ||
        S.get(record, r => r.materialSpu.materialSnapshot.name, 'None'),
    },
    {
      key: 'Finishing',
      title: 'Finishing',
      render: (_, record: S.InquiryOrderItem) =>
        S.get(editRecordRes, r => r[`item_${record.id}_finishing`]) ||
        S.get(record, r => r.printFiles[0].handle.method.join(','), 'None'),
    },
    {
      key: 'Unit price',
      title: `Unit price (${getPriceUnit()})`,
      render: (_, record: S.InquiryOrderItem) =>
        S.get(editRecordRes, r => r[`item_${record.id}_price`]) ||
        S.get(record, r => r.printFiles[0].price, 0),
    },
    {
      key: 'Quantity',
      title: i18nFormat('Quantity'),
      render: (_, record: S.InquiryOrderItem) =>
        S.get(editRecordRes, r => r[`item_${record.id}_quantity`]) ||
        S.get(record, r => r.printFiles[0].printCount, 0),
    },
    {
      key: 'Total',
      title: `Total (${getPriceUnit()})`,
      render: (_, record: S.InquiryOrderItem) =>
        S.get(editRecordRes, r => r[`item_${record.id}_total`]) ||
        S.get(record, r => r.finalPrice.toFixed(2), '0.00'),
    },
    {
      key: 'remark',
      title: i18nFormat('Remark'),
      render: (_, record: S.InquiryOrderItem) => (
        <div style={{ wordBreak: 'break-all' }}>
          {S.get(editRecordRes, r => r[`item_${record.id}_remark`]) ||
            S.get(record, r => r.remark, '-')}
        </div>
      ),
    },
  ];

  const fabs = () => {
    return (
      <div className={styles.fab} id="fab">
        <Tooltip title={'放大'} trigger="hover" placement="left">
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            size="large"
            onClick={async () => {
              setScale(scale + 0.05);

              localStorage.setItem(LK_QUOTATION_SCALE, `${scale + 0.05}`);
            }}
          />
        </Tooltip>
        <Tooltip title={'缩小'} trigger="hover" placement="left">
          <Button
            type="primary"
            shape="circle"
            icon={
              <Tooltip title="缩小">
                <MinusOutlined />
              </Tooltip>
            }
            size="large"
            onClick={async () => {
              setScale(scale - 0.05);

              localStorage.setItem(LK_QUOTATION_SCALE, `${scale - 0.05}`);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className={styles.container} style={{ height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  const getPriceInfo = () => {
    if (!inquiryOrder) return <></>;

    const { price } = inquiryOrder;

    const materialFee = S.get(price, p => p.materialFee, 0);
    const minProductCostFee = S.get(price, p => p.minProductCostFee, 0);
    const subTotal = materialFee + minProductCostFee;

    return (
      <div className={styles.boots}>
        <div className={styles.bootsLine}>
          <div className={styles.bootsLineTitle}>Subtotal:</div>
          <div className={styles.bootsLineVal}>
            {editRecordRes['subtotal'] ||
              `${getPriceUnit()} ${subTotal.toFixed(2)}`}
          </div>
        </div>
        {/* <div className={styles.bootsLine} style={{ marginBottom: 0 }}>
          <div className={styles.bootsLineSubTitle}>Production Fee:</div>
          <div className={styles.bootsLineSubVal}>
            {editRecordRes['subtotal'] ||
              `${getPriceUnit()} ${materialFee.toFixed(2)}`}
          </div>
        </div> */}

        <div className={styles.bootsLine}>
          <div className={styles.bootsLineTitle}>Shipping:</div>
          <div className={styles.bootsLineVal}>
            {editRecordRes['shipping'] ||
              S.get(
                inquiryOrder,
                order => `${getPriceUnit()} ${order.price.postage.toFixed(2)}`,
                'Calculated at checkout',
              )}
          </div>
        </div>

        <div className={styles.bootsLine}>
          <div className={styles.bootsLineTitle}>Discount:</div>
          <div className={styles.bootsLineVal} style={{ marginLeft: '-6px' }}>
            -{' '}
            {couponPrice
              ? `${getPriceUnit()} ${couponPrice.toFixed(2)}`
              : S.get(
                  inquiryOrder,
                  order =>
                    `${getPriceUnit()} ${order.price.discountAmount.toFixed(
                      2,
                    )}`,
                  `${getPriceUnit()} 0.00`,
                )}
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.bootsLine}>
          <div className={styles.bootsLineTitle}>Total:</div>
          <div className={styles.bootsLineVal}>
            {editRecordRes['total'] ||
              (S.get(
                inquiryOrder,
                order => order.price.totalPriceWithoutTax,
              ) !== undefined
                ? S.get(
                    inquiryOrder,
                    order =>
                      `${getPriceUnit()} ${(
                        order.price.totalPriceWithoutTax - couponPrice
                      ).toFixed(2)}`,
                    `${getPriceUnit()} 0.00`,
                  )
                : 'Calculated at checkout')}
          </div>
        </div>

        <div className={styles.bootsLine}>
          <div className={styles.bootsLineTitle}>Price Term:</div>
          <div className={styles.bootsLineVal}>
            {editRecordRes['priceTerm'] || 'DAP'}
          </div>
        </div>
      </div>
    );
  };

  const getBody = () => {
    let buyerCompany, buyerAddress, buyerContact, buyerPhone;

    if (getInvoiceType() !== 'quotation') {
      buyerCompany = S.get(billingInfo, i => i.companyName, '-');
      buyerAddress = S.get(billingInfo, i => i.billingAddress, 'no address');
      buyerContact = S.get(
        inquiryOrder,
        order => order.customer.name,
        'anonymous',
      );
      buyerPhone = S.get(billingInfo, i => i.companyPhoneNumber, '-');
    } else {
      buyerCompany = S.get(
        inquiryOrder,
        order =>
          order.deliver.deliverAddress[0].companyName ||
          order.customer.companyName,
        '-',
      );
      buyerAddress = S.get(
        inquiryOrder,
        order =>
          order.deliver.deliverAddress.map(a => a.formattedString).join('\r\n'),
        'no address',
      );
      buyerContact = S.get(
        inquiryOrder,
        order =>
          order.deliver.representativeName ||
          order.deliver.deliverAddress[0].recipient,
        'anonymous',
      );
      buyerPhone = S.get(
        inquiryOrder,
        order =>
          order.deliver.representativeContactInfo ||
          order.deliver.deliverAddress[0].contactInfo,
        'no phone number',
      );
    }

    return (
      <div className={styles.body} style={{ width: `${200 * scale}mm` }}>
        <div className={styles.wrapper}>
          <div className={styles.topLogo}>
            <img src="https://ufc-oversea.oss-eu-central-1.aliyuncs.com/uoms/images/unionfab_logo_darkblue.png" />

            <Button
              className={styles.noPrintButton}
              onClick={() => {
                window.print();
              }}
            >
              Print or Download PDF
            </Button>
          </div>

          <div className={styles.topTitle}>{getTitle()}</div>

          <div className={styles.topSubTitle}>
            <div style={{ display: 'flex' }}>
              {getInvoiceType() === 'proforma'
                ? 'PI Number'
                : getInvoiceType() === 'commercial'
                ? 'INV Number'
                : 'Quote Number'}
              :
              <div
                style={{
                  marginLeft: '12px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {editRecordRes['quoteNumber'] || inquiryOrderCode}
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              Date:
              <div
                style={{
                  marginLeft: '12px',
                  width: '165px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {editRecordRes['date'] || getDate()}
              </div>
            </div>
          </div>

          <div className={styles.orderDetails}>
            <div className={styles.orderDetailCube}>
              <div className={styles.orderDetailCubeTitle}>Bill To</div>

              <div className={styles.orderDetailLine}>
                <div className={styles.orderDetailLineCol}>Company:</div>
                <div className={styles.orderDetailLineVal}>
                  {editRecordRes['buyerCompany'] || buyerCompany}
                </div>
              </div>
              <div className={styles.orderDetailLine}>
                <div className={styles.orderDetailLineCol}>Address:</div>
                <div className={styles.orderDetailLineVal}>
                  {editRecordRes['buyerAddress'] || buyerAddress}
                </div>
              </div>
              <div className={styles.orderDetailLine}>
                <div className={styles.orderDetailLineCol}>Contact:</div>
                <div className={styles.orderDetailLineVal}>
                  {editRecordRes['buyerContact'] || buyerContact}
                </div>
              </div>
              <div className={styles.orderDetailLine}>
                <div className={styles.orderDetailLineCol}>Phone Number:</div>
                <div className={styles.orderDetailLineVal}>
                  {editRecordRes['buyerPhone'] || buyerPhone}
                </div>
              </div>
              {getInvoiceType() === 'commercial' && (
                <div className={styles.orderDetailLine}>
                  <div className={styles.orderDetailLineCol}>VAT:</div>
                  <div className={styles.orderDetailLineVal}>
                    {S.get(billingInfo, i => i.billingVat, '-')}
                  </div>
                </div>
              )}
            </div>

            <div className={styles.orderDetailCube}>
              <div className={styles.orderDetailCubeTitle}>Supplier</div>

              <div className={styles.orderDetailLine}>
                <div className={styles.orderDetailLineCol}>Company:</div>
                <div className={styles.orderDetailLineVal}>
                  Unionfab AM Technology(shanghai) Co.Ltd
                </div>
              </div>

              <div className={styles.orderDetailLine}>
                <div className={styles.orderDetailLineCol}>Address:</div>
                <div className={styles.orderDetailLineVal}>
                  No.518 Xinzhuan Rd. ,Building 40,Songjiang High Science
                  Technology Park, Shanghai - China
                </div>
              </div>

              {!isRussiaLocal && (
                <div className={styles.orderDetailLine}>
                  <div className={styles.orderDetailLineCol}>Phone Number:</div>
                  <div className={styles.orderDetailLineVal}>
                    {'+86-18221603447'}
                  </div>
                </div>
              )}

              {isCommercialType && (
                <div className={styles.orderDetailLine}>
                  <div className={styles.orderDetailLineCol}>VAT:</div>
                  <div className={styles.orderDetailLineVal}>
                    913101173246481456
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.table}>
            <Table
              rowKey="id"
              pagination={false}
              dataSource={
                inquiryOrder && inquiryOrder.items ? inquiryOrder.items : []
              }
              columns={columns}
            />
          </div>

          {getPriceInfo()}

          <div className={styles.terms}>
            <div className={styles.termsTitle}>Terms:</div>
            {getInvoiceType() === 'quotation' && (
              <div className={styles.termsLine}>
                · The quotation according to the 3D drawing or specifications
                provided by the buyer.
              </div>
            )}
            {getInvoiceType() === 'quotation' && (
              <div className={styles.termsLine}>
                · Validity of Quotation: 14 {i18nFormat('Business Days')}
              </div>
            )}
            <div className={styles.termsLine}>
              · Payment Term: 100% payment before production
            </div>
            <div className={styles.termsLine}>
              · Lead Time:{' '}
              {`${
                inquiryOrder ? S.calcOrderLeadTimes(inquiryOrder).dueDate : '-'
              }
          ${i18nFormat('days')}`}
            </div>
            {getInvoiceType() === 'quotation' && (
              <div className={styles.termsLine}>
                · Please feedback within 24 hours if there is any problem of the
                product
              </div>
            )}
            {getInvoiceType() === 'quotation' && (
              <div className={styles.termsLine}>
                · Sign Non-Disclosure Agreement of both parties
              </div>
            )}
            <div className={styles.termsLine}>
              · Payment method: PayPal, Telegraphic Transfer, Credit Card
            </div>
            <div className={styles.termsLine}>
              <span style={{ fontWeight: 'bold' }}>- Paypal:</span>{' '}
              yanggen@uniontech3d.cn (Multiple Currency)
            </div>
            <div className={styles.termsLine}>
              <span style={{ fontWeight: 'bold' }}>- Bank Information:</span>
            </div>
            <div className={styles.termsLine}>
              Company Name: Unionfab AM Technology (Shanghai) Co.Ltd
            </div>
            <div className={styles.termsLine}>
              Company Address: Room102,Building 40,Songjiang High Science
              Technology Park, Caohejing Developing Zone, No.518 Xinzhuan
              Rd.shanghai
            </div>
            <div className={styles.termsLine}>
              Deposit Bank: BANK OF CHINA SHANGHAI BRANCH JIU TING TOWN
              SUB-BRANCH
            </div>
            <div className={styles.termsLine}>SWIFT: BKCHCNBJ300</div>
            <div className={styles.termsLine}>
              Bank Address: NO.116 QIU JING ROAD, JIU TING TOWN SONG JIANG,
              SHANGHAI
            </div>
            <div className={styles.termsLine}>
              Bank Account USD: 435181022737
            </div>
            <div className={styles.termsLine}>
              Bank Account EUR: 436481786795
            </div>
            <div className={styles.termsLine}>
              <span style={{ fontWeight: 'bold' }}>- Credit Card </span>
              (Available during check out at www.unionfab.com)
            </div>
            <div className={styles.termsLine}>
              · For your order, the following terms and conditions apply:
              Unionfab® terms and conditions.
            </div>
            <div className={styles.termsLine}>
              · If you have any enquiries regarding this quotation, please
              contact your account manager
            </div>
            <div className={styles.termsLine}>- Email: hello@unionfab.com</div>
            {!isRussiaLocal && (
              <div className={styles.termsLine}>
                - Whatsapp/Phone: +86-158-5072-2602
              </div>
            )}

            <div className={styles.termsLine}>
              · The Unionfab Customer Service Agreement
              (https://www.unionfab.com/csa)
            </div>

            <div className={styles.termsLine}>
              After Sales Service
              (https://www.unionfab.com/after-sales-service/)
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      id="OverseaInquiryQuotationV2"
      className={cn(styles.container, styles.a4Page)}
    >
      {getBody()}
      {fabs()}
    </div>
  );
};
