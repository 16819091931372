import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Ellipsis } from '../../../../commons/components/Element/ellipsis';
import { useState } from 'react';

export const OrderCheckoutButton = ({
  className,
  code,
  status,
  hasAddress,
}: {
  className?: string;
  code: string;
  hasAddress: boolean;
  status: S.InquiryOrderStatus;
}) => {
  const nav = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);

  const canCheckout =
    status === 'WAIT_SUBMIT' ||
    // status === 'WAIT_REVIEW' ||
    status === 'WAIT_CUSTOM_CONFIRM';

  if (!canCheckout) return <></>;

  const handleBtnClick = async () => {
    // return setModalVisible(true);
    // 从服务端获取最新的订单信息
    const shopOrder =
      await S.getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderByCode(
        code,
      );
    if (shopOrder.status === 'WAIT_REVIEW') {
      return setModalVisible(true);
    }
    const toPath = hasAddress
      ? `/new_shop_quote/${code}/checkout`
      : `/new_shop_quote/${code}`;
    nav(toPath);
  };

  const btnConfirm = () => {
    setModalVisible(false);
    S.setQueryParamsInQuoteListStore({
      pageNum: 1,
      orderStatus: 'REVIEWING',
    });
  };

  const onOkButton = (
    <Button key="confirm" type="primary" onClick={btnConfirm}>
      {i18nFormat('Confirm')}
    </Button>
  );

  return (
    <>
      <Button onClick={handleBtnClick} type="primary" className={className}>
        <Ellipsis maxWidth={88}>
          {i18nFormat(
            status == 'WAIT_CUSTOM_CONFIRM' ? 'Checkout' : 'Continue',
          )}
        </Ellipsis>
      </Button>
      <Modal
        closable={false}
        open={modalVisible}
        footer={[onOkButton]}
        title={i18nFormat('Notice')}
      >
        <div>
          Due to changes in order information, the order status changes to
          Reviewing. Click Confirm to transfer to the Reviewing order list.
        </div>
      </Modal>
    </>
  );
};

OrderCheckoutButton.displayName = 'OrderCheckoutButton';
