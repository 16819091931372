import { i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, Space } from 'antd';
import { useMemo } from 'react';

import { useAppNavigate } from '@/features/shared';

import { Ellipsis } from '../../../../commons/components/Element/ellipsis';
import { OrderCheckoutButton } from '../OrderCheckoutButton';
import { OrderCloseButton } from '../OrderCloseButton';
import { OrderQualityInspectionButton } from '../OrderQualityInspectionButton';
import styles from './index.module.less';
import { OrderInvoiceBtn } from './OrderInvoiceBtn';
import { OrderPayBtn } from './OrderPayBtn';

export const OrderActions = ({
  order,
  isQuoteTable,
  onRefresh,
}: {
  order: S.ShopOrder;
  isQuoteTable: boolean;
  onRefresh: () => void;
}) => {
  if (!order || !order.status || !order.code) return <></>;

  const nav = useAppNavigate();

  const { status, code, delivery } = order;

  const detailsBtn = useMemo(() => {
    const visible = !(
      // status == 'WAIT_SUBMIT' ||
      // status == 'WAIT_REVIEW' ||
      // status == 'WAIT_CUSTOM_CONFIRM' ||
      (status == 'ORDER_CLOSED')
    );

    if (!visible) return <></>;

    const goToOrderDetail = () => {
      if (isQuoteTable) {
        nav.navigateToQuoteDetail(code);
      } else {
        nav.navigateToOrderDetail(code);
      }
    };

    return (
      <Button
        type="link"
        className={styles.linkBtn}
        onClick={() => goToOrderDetail()}
      >
        <Ellipsis maxWidth={88}>{i18nFormat('View Detail')}</Ellipsis>
      </Button>
    );
  }, [isQuoteTable, code]);

  const invoiceBtn = useMemo(() => {
    let invoiceType = undefined;

    if (
      [
        'CUSTOM_CONFIRM',
        'WAIT_PRINT',
        'FILE_HANDLE',
        'PRINTING',
        'HANDLE',
        'WAIT_DELIVER',
      ].includes(status)
    ) {
      invoiceType = 'Proforma';
    }

    if (['WAIT_RECEIVE', 'ORDER_COMPLETE'].includes(status)) {
      invoiceType = 'Commercial';
    }

    if (!invoiceType) {
      return <></>;
    }
    return (
      <Button
        type="link"
        className={styles.linkBtn}
        onClick={() => nav.navToInvoicePage(code, invoiceType, true)}
      >
        <Ellipsis maxWidth={88}>
          {i18nFormat(`${invoiceType} Invoice`)}
        </Ellipsis>
      </Button>
    );
  }, [status, code]);

  return (
    <Space size="small" className={styles.orderActions}>
      <OrderCheckoutButton
        code={code}
        status={status}
        className={styles.defaultBtn}
        hasAddress={S.get(delivery, d => d.items, []).length > 0}
      />
      <OrderPayBtn order={order} className={styles.defaultBtn} />
      {detailsBtn}
      <OrderInvoiceBtn order={order} className={styles.defaultBtn} />
      <OrderQualityInspectionButton linkBtn shopOrder={order} />
      {/** 暂时隐藏 Quote 的编辑功能 */}
      {/* {isQuoteTable && (
        <OrderContinueQuoteBtn className={styles.defaultBtn} order={order} />
      )} */}
      {/* {canCloseOrder && (
        <OrderCloseButton
          order={order}
          onClose={onRefresh}
          className={styles.defaultBtn}
        />
      )} */}
    </Space>
  );
};
