import './index.css';
import './index.less';

import * as S from '@unionfab/ufc-shop-commons';
import { UfcShopTokenUtils } from '@unionfab/ufc-shop-commons';
import { StrictMode, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app/web';

const switchProxy = (apiEndpoint: string) => {
  const token = UfcShopTokenUtils.getAccessToken();
  const apiConfig = { apiEndpoint };
  const composedApi = S.getUfcShopComposedApi();
  if (composedApi && typeof composedApi.setApiConfig === 'function') {
    composedApi.setApiConfig(apiConfig);
  } else {
    console.warn('getUfcShopComposedApi did not return a valid object');
  }
  // 设置请求的路径
  S.setUfcShopRuntimeEnv(apiConfig);
  if (token) {
    // 初始化请求对象
    S.setupDtcRequestApi({ Authorization: `Bearer ${token}` });
  } else {
    S.setupDtcRequestApi({});
  }
  // 刷新当前的 Token 信息
  UfcShopTokenUtils.refreshToken();
};

function AppWithHooks() {
  const channelRef = useRef<BroadcastChannel | null>(null);
  const ORDER_ENDPOINT = useRef(window.gConfig.ORDER_ENDPOINT);
  const PROXY_ORDER_ENDPOINT = useRef(
    `${window.location.origin}/api/dtc-sh-proxy`,
  );
  const proxy = localStorage.getItem('uoms_proxy') === 'true';
  switchProxy(proxy ? PROXY_ORDER_ENDPOINT.current : ORDER_ENDPOINT.current);
  useEffect(() => {
    if (!channelRef.current) {
      channelRef.current = new BroadcastChannel('proxy-channel');
      channelRef.current.onmessage = event => {
        const { action, proxy } = event.data;
        console.log('Received message:', event.data);
        if (action === 'switchProxy') {
          switchProxy(
            proxy ? PROXY_ORDER_ENDPOINT.current : ORDER_ENDPOINT.current,
          );
        }
      };
    }
    return () => {
      // channelRef.current?.close();
    };
  }, []);
  return <App />;
}

export function render(elementId: string, getContent: () => JSX.Element) {
  let element: HTMLElement | undefined = undefined;

  document.addEventListener('DOMContentLoaded', function (_) {
    if (element) {
      return;
    }
    const e = document.getElementById(elementId);
    if (e) {
      element = e as HTMLElement;
    }
    if (!element) {
      console.warn('Mount point not found: ', elementId);
      return;
    }

    ReactDOM.createRoot(element as HTMLElement).render(
      <StrictMode>{getContent()}</StrictMode>,
    );
  });
}

const bootstrap = async () => {
  window.gConfig = new S.UfcShopSiteConfig(window.gConfig);
  render('root', () => <AppWithHooks />);
};

bootstrap().catch(r => console.warn('error rendering', r));
