import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import * as S from '../../schema';

interface ShopCouponStoreState {
  shopCoupon: any[];
  saveCoupon: (orderCode: string, coupon: S.InquiryCoupon) => void;
  clearCoupon: (orderCode: string) => void;
  getCoupon: (orderCode: string) => S.InquiryCoupon | null;
  clearExpiredCoupons: () => void;
}

export const useShopCouponStore: UseBoundStore<
  StoreApi<ShopCouponStoreState>
> = create<ShopCouponStoreState>()(
  devtools(
    persist(
      (set, get) => ({
        shopCoupon: [],
        saveCoupon: (orderCode: string, coupon: S.InquiryCoupon) => {
          set(state => ({
            shopCoupon: [
              ...state.shopCoupon.filter(item => item.orderCode !== orderCode),
              { orderCode, ...coupon },
            ],
          }));
        },
        clearCoupon: orderCode => {
          set(state => ({
            shopCoupon: state.shopCoupon.filter(
              item => item.orderCode !== orderCode,
            ),
          }));
        },


        getCoupon: orderCode => {
          return (
            get().shopCoupon.find(item => item.orderCode === orderCode) || null
          );
        },

        clearExpiredCoupons: () => {
          const now = new Date().getTime();
          set(state => ({
            shopCoupon: state.shopCoupon.filter(coupon => {
              const endTime = new Date(coupon.endTime).getTime();
              return now <= endTime && coupon.status === "UNUSED";
            }),
          }));
        },
      }),
      {
        name: 'shop-coupon-storage',
      },
    ),
    {
      name: 'shopCouponStore',
    },
  ),
);
