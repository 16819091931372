import { InfoCircleTwoTone, UpOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Descriptions, message, Tooltip } from 'antd';
import * as React from 'react';
import { useState } from 'react';

import { useInquiryQuoteEditorStore } from '@/stores';
import { useInquiryMaterialStore } from '@/stores';

import {
  isTechnicalDrawingRequired,
  OrderItemColor,
  OrderItemFinish,
  OrderItemInserts,
  OrderItemMaterialSpu,
  OrderItemNotes,
  OrderItemProcess,
  OrderItemPunchCount,
  OrderTechItemDrawings,
} from '../QuoteOrderItemFields';
import styles from './index.module.less';

export const QuoteOrderItemDetails = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const { technology, editingFileIds, toggleOrderItemFileEditing } =
    useInquiryQuoteEditorStore();
  const { materials } = useInquiryMaterialStore();

  const curMaterial = S.get(materials, () =>
    materials.find(m => orderItem.materialSpu.materialId === m.id),
  );

  const curMaterialTypeName = !!curMaterial
    ? S.get(curMaterial, c => c.materialType.name, '')
    : '';

  const isSLMMaterial = !!curMaterial
    ? curMaterialTypeName.toLocaleUpperCase().includes('SLM')
    : false;

  const isCNC = technology.type === 'CNC';
  const editing = !!editingFileIds.find(
    v => v === orderItem.printFiles[0].fileId,
  );

  const toggleEditing = () => {
    if (!editing) {
      toggleOrderItemFileEditing(!editing, orderItem);
    }
  };

  const hasNotes = orderItem.remark && orderItem.remark.trim().length !== 0;
  const [showMoreSettings, setShowMoreSettings] = useState(
    isTechnicalDrawingRequired(technology, orderItem),
  );

  function toggleShowMoreSettings() {
    if (showMoreSettings && isTechnicalDrawingRequired(technology, orderItem)) {
      message.warning('Please upload a technical drawing file.');
      return;
    }
    setShowMoreSettings(!showMoreSettings);
  }

  const commonFieldsStyles: React.CSSProperties = editing
    ? {}
    : {
        cursor: 'pointer',
      };

  const labelStyle: React.CSSProperties = {
    minWidth: editing ? 150 : 90,
    justifyContent: 'flex-end',
  };

  return (
    <div
      className={styles.orderItemDetails}
      style={commonFieldsStyles}
      onClick={toggleEditing}
    >
      <Descriptions column={editing ? 1 : 2} labelStyle={labelStyle}>
        {/* {editing && (
          <Descriptions.Item label={i18nFormat('Material Groups')} span={1}>
            <OrderItemMaterialFilterGroup orderItem={orderItem} />
          </Descriptions.Item>
        )} */}
        <Descriptions.Item
          span={1}
          label={
            <span className={styles.fieldLabel}>
              <Tooltip
                className={styles.fieldTip}
                color="white"
                title={
                  <a
                    href={'https://www.unionfab.com/materials'}
                    style={{ color: 'black', textDecoration: 'underline' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {i18nFormat('Learn about our materials')}
                  </a>
                }
              >
                <InfoCircleTwoTone twoToneColor="#f89902" />
              </Tooltip>
              {i18nFormat('Material')}
            </span>
          }
        >
          <div className={styles.fieldContent}>
            <OrderItemMaterialSpu orderItem={orderItem} />
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={i18nFormat('Process')} span={1}>
          <OrderItemProcess orderItem={orderItem} />
        </Descriptions.Item>
        <Descriptions.Item label={i18nFormat('Raw Color')} span={1}>
          <OrderItemColor orderItem={orderItem} />
        </Descriptions.Item>
        <Descriptions.Item
          span={1}
          label={
            <span className={styles.fieldLabel}>
              <Tooltip
                className={styles.fieldTip}
                color="white"
                title={
                  <a
                    href={'https://www.unionfab.com/service_cat/finishing'}
                    style={{ color: 'black', textDecoration: 'underline' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {i18nFormat('Learn about our finishes')}
                  </a>
                }
              >
                <InfoCircleTwoTone twoToneColor="#f89902" />
              </Tooltip>
              {i18nFormat('Finish')}
            </span>
          }
        >
          <div className={styles.fieldContent}>
            <OrderItemFinish orderItem={orderItem} />
          </div>
        </Descriptions.Item>

        {/* <Descriptions.Item
          label={i18nFormat('Reference Pictures')}
          className={styles.refPictures}
        >
          <OrderItemPreviewPictures orderItem={orderItem} />
        </Descriptions.Item> */}

        {!editing && hasNotes && (
          <Descriptions.Item
            label={i18nFormat('Notes')}
            className={styles.customizeItem}
          >
            <OrderItemNotes
              orderItem={orderItem}
              editing={editing!}
              className={styles.fieldContent}
            />
          </Descriptions.Item>
        )}
      </Descriptions>

      {editing && (
        <Descriptions column={1} labelStyle={labelStyle}>
          <Descriptions.Item
            className={styles.showMore}
            label={
              <span onClick={toggleShowMoreSettings}>
                {i18nFormat('More Settings')} &nbsp;&nbsp;&nbsp;
              </span>
            }
          >
            <UpOutlined
              onClick={toggleShowMoreSettings}
              style={{
                color: '#f89902',
                cursor: 'pointer',
                transition: 'all .3s ease-in',
                transform: `rotate(${showMoreSettings ? 0 : 180}deg)`,
              }}
            />
          </Descriptions.Item>
          {showMoreSettings && (
            <>
              {(isCNC || isSLMMaterial) && (
                <Descriptions.Item
                  className={styles.customizeItem}
                  label={i18nFormat('Threads and Tapped Holes')}
                >
                  <OrderItemPunchCount orderItem={orderItem} />
                </Descriptions.Item>
              )}
              {!isCNC && !isSLMMaterial && (
                <Descriptions.Item
                  label={i18nFormat('Inserts')}
                  className={styles.customizeItem}
                >
                  <OrderItemInserts orderItem={orderItem} />
                </Descriptions.Item>
              )}
              <Descriptions.Item
                label={i18nFormat('Notes')}
                className={styles.customizeItem}
              >
                <OrderItemNotes
                  editing={editing!}
                  orderItem={orderItem}
                  className={styles.fieldContent}
                />
              </Descriptions.Item>

              <Descriptions.Item
                label={i18nFormat('Technical drawing')}
                className={styles.customizeItem}
                contentStyle={{ width: 'calc(100% - 150px)' }}
              >
                <OrderTechItemDrawings
                  orderItem={orderItem}
                  editing={editing!}
                  className={styles.fieldContent}
                />
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      )}
    </div>
  );
};
