import { Ellipsis } from '@/commons/components/Element/ellipsis';
import { getUfcShopComposedApi, i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const OrderPayBtn = ({
  order,
  className,
}: {
  order: S.ShopOrder;
  className?: string;
}) => {
  const { code, status, inQuotation } = order;

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const isUnpaid = S.UnpaidInquiryOrderStatusList.includes(status);

  const { data: priceInfo, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    enabled: isUnpaid && !!order,
    queryKey: ['priceInfo', order],
    refetchInterval: q => (q && q.hasComputingItem ? 3000 : false),
    queryFn: () => {
      const expressId = S.get(
        order,
        o => o.requirements.customerSelections.delivery.expressId,
      );

      return getUfcShopComposedApi().shopOrderQueryApi.calcPrice(code, {
        expressId,
      });
    },
  });

  // TODO 订单confirm 跳转到支付页面
  const payNow = async () => {
    try {
      setLoading(true);
      const resp =
        await getUfcShopComposedApi().shopOrderUpdateApi.confirmShopOrderByCode(
          code,
        );
      // 跳转支付页面
      if (resp) {
        navigate(`/new_shop_quote/${code}/payment`);
        setLoading(false);
      }
    } catch (error) {
      console.error('-----error', error);
    } finally {
      setLoading(false);
    }
  };

  // 订单状态为WAIT_REVIEW，价格非计算中并且有number类型值
  if (
    status === 'WAIT_REVIEW' &&
    priceInfo &&
    !priceInfo.hasComputingItem &&
    !isLoading &&
    S.get(priceInfo, p => p.total) !== 'RFQ' &&
    !inQuotation
  ) {
    return (
      <Button
        type="primary"
        className={className}
        onClick={payNow}
        loading={loading}
        disabled={loading}
        style={{
          backgroundColor: loading ? '#fff' : '',
          borderColor: loading ? '#fff' : '',
        }}
      >
        <Ellipsis maxWidth={88}>{i18nFormat('Pay Now')}</Ellipsis>
      </Button>
    );
  } else {
    return <></>;
  }
};
