import * as S from '@unionfab/ufc-shop-commons';
import { Form, Select } from 'antd';
import cn from 'classnames';
import { flatten, pick, uniq, uniqBy } from 'lodash';
import { CSSProperties, useEffect, useMemo } from 'react';

import {
  useInquiryHandleMethod,
  useInquiryMaterialStore,
} from '../../../../../stores';
import styles from './index.module.less';

export interface QuoteFinishSelectProps {
  className?: string;
  style?: CSSProperties;

  materialTypeIds?: string[];
  value?: {
    method: string[];
    desc: string; // 除喷漆外的描述字段
    desc1?: string; // 喷漆描述字段
  };
  onChange?: (_: { finishMethod: string; color: S.HandleMethodColor }) => void;
}

export const QuoteFinishSelect = ({
  className,
  style,

  materialTypeIds = [],
  value,
  onChange,
}: QuoteFinishSelectProps) => {
  const { materialTypeIdToHandleMethods, loadInquiryHandleMethods } =
    useInquiryHandleMethod();

  const { materialTypes: inquiryMaterialTypes } = useInquiryMaterialStore();

  const materialTypes = (inquiryMaterialTypes || []).filter(m =>
    uniq(materialTypeIds).includes(m.id),
  );

  useEffect(() => {
    if (!S.isValid(materialTypeIdToHandleMethods)) {
      loadInquiryHandleMethods();
    }
  }, []);

  const availableHandleMethods = useMemo(() => {
    if (
      S.isValidArray(materialTypeIds) &&
      S.isValid(materialTypeIdToHandleMethods)
    ) {
      return uniqBy(
        flatten(
          Object.values(pick(materialTypeIdToHandleMethods, materialTypeIds)),
        ),
        m => m.id,
      );
    }

    return [];
  }, [materialTypeIdToHandleMethods, materialTypes]);

  const colorDesc = S.get(value, v => v.desc, '');
  const initialHandleMethod = S.get(
    value,
    v => v.method.filter(m => m !== '')[0],
  );

  /** 选项中是否包含当前的值 */
  const hasInitialInOptions = availableHandleMethods.some(
    m => m.name === initialHandleMethod,
  );

  const valueStr = hasInitialInOptions
    ? `${initialHandleMethod} ${
        colorDesc.length > 0 ? `-PANTONE ${colorDesc}` : ''
      }`.trim()
    : 'Standard'; // 如果选项中不包含上一个值，则设置为默认的 Standard
  return (
    <div
      id="QuoteFinishSelect"
      style={style}
      className={cn(className, styles.container)}
    >
      <Form.Item name="finish-select" initialValue={valueStr}>
        <Select
          allowClear
          value={valueStr}
          options={[
            { value: '', label: 'Standard' }, // Add "Standard" as an option
            ...(availableHandleMethods || []).map(m => ({
              value: m.name,
              label: S.i18nFormat(m.name),
            })),
          ]}
          onChange={v => {
            onChange &&
              onChange({ finishMethod: v, color: { name: '', color: '' } });
          }}
        />
      </Form.Item>
    </div>
  );
};

QuoteFinishSelect.displayName = 'QuoteFinishSelect';
