import * as S from '@unionfab/ufc-shop-commons';
import {
  getUfcShopComposedApi as api,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';
import { Button, Modal, Popover, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cn from 'classnames';
import { head } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { Ellipsis } from '@/commons/components/Element/ellipsis';
import { RfqTooltip } from '@/commons/components/Element/RfqTooltip';
import { useInquiryMaterialStore } from '@/stores';

import styles from './index.module.less';

export const OrderItemPrintFilesInfo = ({
  order,
  className,
  isQuote = false,
}: {
  className?: string;
  order: S.ShopOrder;
  isQuote?: boolean;
}) => {
  const [modalVisible, toggleModalVisible] = useState<boolean>(false);

  const { code, status, items } = order;

  const isUnpaid = S.UnpaidInquiryOrderStatusList.includes(status);

  const { materialTypes: inquiryMaterialTypes } = useInquiryMaterialStore();

  /** 未支付订单报价信息 */
  const { data: priceInfo, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [code, 'quotation'],
    enabled: isUnpaid && modalVisible,
    refetchInterval: q => (q && q.hasComputingItem ? 3000 : false),
    queryFn: () => {
      const expressId = S.get(
        order,
        o => o.requirements.customerSelections.delivery.expressId,
      );

      return api().shopOrderQueryApi.calcPrice(code, { expressId });
    },
  });

  const targetItem = head(items || []);

  const getInquiryMaterialType = (item: S.ShopOrderItem) => {
    if (!!item && S.isValidArray(inquiryMaterialTypes)) {
      const inquiryMaterialTypeId = S.get(
        item,
        i => i.materialVO.materialTypeId,
      );

      return inquiryMaterialTypes.find(
        type => type.id === inquiryMaterialTypeId,
      );
    }
  };

  const getHandleMethod = (item: S.ShopOrderItem) => {
    if (!!item) {
      const res = S.get(item, i => i.handleRemark.method, []).join(',');
      return res || 'Standard';
    }

    return '-';
  };

  const renderItem = ({
    item,
    items = [],
  }: {
    item: S.ShopOrderItem;
    items?: S.ShopOrderItem[];
  }) => {
    if (!item) return <></>;

    const modelFile = S.get(
      order,
      o => o.extraVO.fileMap[item.fileId],
      S.DefaultNullD3Model,
    );

    return (
      <div className={cn(styles.printFiles)}>
        <D3ModelAvatar
          size={60}
          model={modelFile}
          className={styles.orderItemAvatar}
          unit={S.get(item, i => i.lengthUnit, 'MILLIMETER')}
        />

        <div className={styles.orderItemInfo}>
          <Ellipsis maxWidth="100%" className={styles.filename}>
            {`${S.get(modelFile, m => m.name, '-')} * ${item.printCount}`}
          </Ellipsis>

          <Ellipsis maxWidth="100%">
            {`${i18nFormat('Material: ')} ${S.get(
              getInquiryMaterialType(targetItem),
              t => t.name,
              '-',
            )}`}
          </Ellipsis>

          <Ellipsis maxWidth="100%">{`${i18nFormat(
            'Finish',
          )}: ${getHandleMethod(targetItem)}`}</Ellipsis>

          {!!items && items.length > 1 && (
            <Button
              size="small"
              className={styles.btn}
              onClick={() => toggleModalVisible(true)}
            >
              {i18nFormat('View All')}
              {items.length}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const allItems = useMemo(() => {
    if (!S.isValidArray(items)) return <></>;

    const renderSubTotal = (itemId: string) => {
      const price = isUnpaid ? priceInfo : order.price;

      if (isUnpaid && isLoading) return <Spin size="small" />;

      if (!itemId || !price) return '-';

      const itemPrice = S.get(price, o => o.production.items, []).find(
        i => i.id == itemId,
      );
      const status = S.get(itemPrice, i => i.status);

      if (status == 'COMPUTING') return <Spin size="small" />;

      const amount = S.get(itemPrice, i => i.price.amount);
      const currency = S.get(itemPrice, i => i.price.currency);

      return status === 'RFQ'
        ? 'RFQ'
        : S.toPriceStringWithOption(amount, { currency, rfq: true });
    };

    const columns: ColumnsType<S.ShopOrderItem> = [
      {
        width: '70%',
        title: i18nFormat('All Items & Quantity'),
        render: item => renderItem({ item }),
      },
      {
        width: '30%',
        title: i18nFormat('Subtotal'),
        render: ({ id }) => renderSubTotal(id),
      },
    ];

    return (
      <div style={{ paddingTop: '1.25rem' }}>
        <Table
          columns={columns}
          dataSource={items}
          pagination={false}
          tableLayout="fixed"
          className={styles.ordersTable}
        />
      </div>
    );
  }, [items, priceInfo, isUnpaid, isLoading]);

  return (
    <div className={className}>
      {renderItem({ item: targetItem, items })}
      <Modal
        footer={false}
        open={modalVisible}
        width={800}
        onCancel={() => toggleModalVisible(false)}
        bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      >
        {allItems}
      </Modal>
    </div>
  );
};
