import * as S from '../../../../schema';
import { getTenantIdFromGConfig } from '../../..';
import { AliyunSlsApmTrackerUtils } from '../../../vendor-apis/aliyun-apm';
import { BaseAxiosApi } from '../../shared';

export class UfcShopOrderUpdateApi extends BaseAxiosApi {
  /** 创建订单 */
  async createShopOrder(requestParams: Partial<S.ShopOrderRequestParams>) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'post-/v2/shop/order',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order`,
        request_body: JSON.stringify(requestParams),
      },
      async () => {
        const { data } = await this.post<{ code: string }>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order`,
          params: { tenantId: getTenantIdFromGConfig() },
          data: requestParams,
          adapter: (error: any) => {
            const { data } = error;
            if (data.err.reason) {
              const CreateShopOrderErrorMapping = [
                {
                  code: 'ANONYMOUS_SESSION_LIMIT_EXCEEDED',
                  reason: 'anonymous session can only create one inquiry order',
                },
                {
                  code: 'INVALID_OPERATION_TYPE',
                  reason: 'invalid op-type',
                },
              ];
              const errOne = CreateShopOrderErrorMapping.find(codeObj =>
                data.err.reason.includes(codeObj.reason),
              );
              return {
                statusCode: error.status,
                LogCode: errOne?.code || 'UNKNOWN_CODE',
                LogResponse: JSON.stringify(data),
              };
            }
            return {
              statusCode: error.status,
              LogCode: 'UNKNOWN_CODE',
              LogResponse: JSON.stringify(data),
            };
          },
        });

        return data.code;
      },
    );
  }

  /** 从匿名订单创建新订单 */
  async createShopOrderFromAnonymousOrder(
    sessionId: string,
    orderCode: string,
  ) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'post-/v2/shop/orders/create_by_anonymous_order',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/orders/create_by_anonymous_order`,
        request_body: JSON.stringify({
          code: orderCode,
          sessionId: sessionId,
        }),
      },
      async () => {
        const {
          data: { code },
        } = await this.post<{ code: string }>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/orders/create_by_anonymous_order`,
          // params: { tenantId: getTenantIdFromGConfig() },
          data: {
            code: orderCode,
            sessionId: sessionId,
          },
        });

        return code;
      },
    );
  }

  /** 更新订单 */
  async updateShopOrderByCode(
    code: string,
    requestParams: S.ShopOrderRequestParams,
  ) {
    // 开始监听报价结果
    AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().onEventStart({
      event_type: 'wait-auto-quotation',
      attr_order_code: code,
    });

    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'post-/v2/shop/order/:orderCode',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}`,
        request_body: JSON.stringify(requestParams),
        request_order_code: code,
      },
      async () => {
        const { status } = await this.post<{ code: string }>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}`,
          params: { tenantId: getTenantIdFromGConfig() },
          data: requestParams,
        });

        return status === 'ok';
      },
    );
  }

  /** 提交订单 */
  async submitShopOrderByCode(code: string) {
    const { status } = await this.post<{ code: string }>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/submit`,
      params: { tenantId: getTenantIdFromGConfig() },
    });

    return status === 'ok';
  }

  /** 客户确认订单 */
  async confirmShopOrderByCode(code: string) {
    const { status } = await this.post<{ code: string }>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/confirm`,
      params: { tenantId: getTenantIdFromGConfig() },
    });

    return status === 'ok';
  }

  /** 客户确认查看过报价更新 */
  async confirmShopOrderQuotation(code: string, revisionId: string) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'post-/v2/shop/order/:orderCode/quotation/:revisionId',
        request_body: JSON.stringify({ code, revisionId }),
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/quotation/${revisionId}`,
      },
      async () => {
        const { status } = await this.post({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/quotation/${revisionId}`,
          params: { tenantId: getTenantIdFromGConfig() },
        });

        return status == 'ok';
      },
    );
  }

  async updateShopOrderBillingInfo(
    code: string,
    billingInfo: S.ShopOrderBillingInfo,
  ) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'post-/v2/shop/order/:orderCode/billing_info',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/billing_info`,
        request_body: JSON.stringify({ code, billingInfo }),
      },
      async () => {
        const { status } = await this.post({
          data: billingInfo,
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/billing_info`,
        });

        return status == 'ok';
      },
    );
  }
}
